<template>
	<div>
		<el-container>
		  <el-header style='position: relative;left: 0;top: 0;'>
			  <div class="title">
				  <span v-if="isCollapse" class="el-icon-s-unfold" style="font-size: 35px;vertical-align: middle;cursor: pointer;" @click="changeAside"></span>
				 <span v-else class="el-icon-s-fold" style="font-size: 35px;vertical-align: middle;cursor: pointer;" @click="changeAside"></span> 
			  	 Passer后台管理系统
			  </div>
			  <div class="header-right">
			  	<div class="header-user-con">
			  		<div class="btn-fullscreen">
			  			<span class="el-icon-full-screen" style="font-size: 20px;cursor: pointer;"></span>
			  		</div>
					<div class="user-avator">
						<el-avatar :size="40" :src="avatar"></el-avatar>
					</div>
					<div class="user-info">
						<el-dropdown @command="handleLoginout">
						  <span class="el-dropdown-link">
						    {{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
						  </span>
						  <el-dropdown-menu slot="dropdown">
						    <el-dropdown-item  command="a">退出登录</el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>
					</div>
			  	</div>
			  </div>
		  </el-header>
		  <el-container>
		     <el-menu 
			 :default-active="currentIndex" 
			 style='position: relative;left: 0;top: 0;'
			 class="el-menu-vertical-demo" 
			 :collapse="isCollapse"
			 >
				  <el-menu-item v-for="(item,index) in sideData" :index="item.index" :key='index'  @click= 'ChangeUrl(item.router,item.index,item)'>
				    <i :class="item.icon"></i>
				    <span slot="title">{{item.name}}</span>
				  </el-menu-item>
		    </el-menu>
			
		    <el-main>
				<div class="tags">
					<ul style="padding: 0;">
						<li class="tags-li" v-for="(item,index) in headerTag">
							<el-tag 
							style='line-height: 23px;height: 23px;' 
							@click='tagJump(item)' 
							@close='tagShut(item)' 
							effect="dark"
							:type='item.path===currentPath ? "":"info"'
							closable
							>
							{{item.val}}
							</el-tag>
					    </li>
					</ul>
					<div class="tags-close-box">
						<el-dropdown @command="handleCommand">
						  <el-button type="primary" size='mini'>
						    标签选项<i class="el-icon-arrow-down el-icon--right"></i>
						  </el-button>
						  <el-dropdown-menu slot="dropdown">
						    <el-dropdown-item command="other">关闭其他</el-dropdown-item>
						    <el-dropdown-item command="all">关闭所有</el-dropdown-item>
						  </el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<div class="content" style="padding-bottom: 40px;">
					<router-view></router-view> <!-- 路由匹配的组件将在这里显示 -->
				</div>
			</el-main>
		  </el-container>
		</el-container>
	</div>
</template>

<script>
	import { adminLoginOut } from '../../server/api/index.js';
	import { removeToken,getUserInfo } from '../../server/api/store.js'
	import { mapState } from 'vuex'
	export default {
	    data() {
	      return {
	        isCollapse: false,
			currentIndex:null,
			currentPath:null,
			currentTagName:null,
			sideData:[
				{
					index:'1',
					icon:'el-icon-s-home',
					name:'系统首页',
					router:'/admin/home'
				},
				{
					index:'2',
					icon:'el-icon-user-solid',
					name:'用户管理',
					router:'/admin/user'
				},
				{
					index:'3',
					icon:'el-icon-s-order',
					name:'文章发布',
					router:'/admin/article'
				},
				{
					index:'4',
					icon:'el-icon-s-release',
					name:'文章管理',
					router:'/admin/articleAdmin'
				},
				{
					index:'5',
					icon:'el-icon-s-promotion',
					name:'友链管理',
					router:'/admin/friendAdmin'
				},
				{
					index:'6',
					icon:'el-icon-s-release',
					name:'音乐播放器相关',
					router:'/admin/musicAbout'
				},
				{
					index:'7',
					icon:'el-icon-edit',
					name:'关于本站和我',
					router:'/admin/myWebsite'
				},
				{
					index:'8',
					icon:'el-icon-date',
					name:'时间线',
					router:'/admin/timeStamp'
				},
				{
					index:'9',
					icon:'el-icon-chat-dot-round',
					name:'评论管理',
					router:'/admin/remarkAdmin'
				},
				{
					index:'10',
					icon:'el-icon-paperclip',
					name:'分享审核',
					router:'/admin/share'
				},
				{
					index:'11',
					icon:'el-icon-monitor',
					name:'项目实战',
					router:'/admin/combat'
				},
				{
					index:'12',
					icon:'el-icon-setting',
					name:'操作记录',
					router:'/admin/operation'
				}
			],
			tagData:[]
	      };
	    },
	    methods: {
	      changeAside(){
			  return this.isCollapse = ! this.isCollapse;
		  },
		  ChangeUrl(index,path,each){
			  this.$store.commit('changeHeaderTag',each);
			  return this.$router.push({path:index})
		  },
		  tagJump(path){
			 this.$router.push({path:path.path});//Header导航栏跳转
		  },
		  tagShut(item){
			 this.headerTag.splice(this.headerTag.indexOf(item), 1);//标签的关闭
			 if(this.headerTag.length!==0){
				 if(item.path===this.currentPath){
				    let currentArr = this.headerTag;
				    this.$router.push({path:currentArr[0].path});
				 }
			 }else{
				 this.$router.push({path:'/admin/home'});
				 this.$store.commit('changeHeaderTag',{ name:'系统首页',router:'/admin/home',index:'1'});
			 }
		  },
		  getCurrentPathTag(){            //处理页面在刷新的情况下,headerTag不消失的问题
			  let each = {
				  name:this.currentTagName==='欢迎来到首页'?'系统首页':this.currentTagName,
				  router:this.currentPath,
				  index:this.currentIndex
			  };
			  this.$store.commit('changeHeaderTag',each);
			},
		  handleCommand(command) {
			  if(command==='all'){
				  this.$store.commit('clearHeaderTag');//先 清空tag标签组
				  this.$router.push({path:'/admin/home'});
				  this.$store.commit('changeHeaderTag',{ name:'系统首页',router:'/admin/home',index:'1'});
			  }else{
				  this.$store.commit('deleteOtherTag',this.currentPath);
			  }
		  },
		  async handleLoginout(){
			  let ref = await adminLoginOut();
			  if(ref.success){
				  this.$message.success(ref.msg);
				  removeToken();//清除用户的登录信息
				  return this.$router.push({path:'/admin'});//返回登录页
			  }
		  }
	    },
		watch: {
		    $route: {                                       //路由监听
		      handler: function(route) {
				  this.currentPath = route.path;
				  this.currentTagName = route.meta.title;
				  this.currentIndex = route.name;
			  },
		      immediate: true
		    }
		},
		computed:{
			 ...mapState(['headerTag']),
			 userName(){
				 return getUserInfo().name
			 },
			 avatar(){
				 return getUserInfo().avatar
			 }
		},
		mounted() {
			this.getCurrentPathTag();
		}
	  }
</script>

<style>
/deep/ html, body {
	     height: 100%;
	     overflow: auto;
	    }
body{
	     padding: 0;
	     margin: 0;
		 overflow-y:hidden;
		 padding-right:0!important;
 }
	/deep/ *{
		padding: 0;
		margin: 0;
	}
  .el-header{
    background-color: #242f42;
    color: #fff;
    line-height: 60px;
  }
  
  .el-aside {
    background-color: rgb(50, 65, 87);
    color: rgb(191, 203, 217);
   }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
   }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }	
  
 /**以下为自定义样式*/
.title{
	width: 200px;
	display: inline-block;
}
.header-right{
	display: inline-block;
	float: right;
	padding-right: 40px;
	height: 100%;
}
.header-user-con{
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.user-avator{
	height: 100%;
	line-height: 87px;
}
.btn-fullscreen{
	margin-top: -25px;
	margin-right: 15px;
}
.user-info{
	margin-top: -25px;
	margin-left: 15px;
}
.el-dropdown-link {
    cursor: pointer;
    color: #fff;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
 .el-menu-vertical-demo:not(.el-menu--collapse) {
     width: 205px;
     min-height: 670px;
   } 
  .el-menu{
	  background-color:  rgb(50, 65, 87);
   }
   .el-menu-item {
	  color: rgb(191, 203, 217); 
   }
   .el-menu--collapse {
       width: 64px;
       height: 670px;
   }
   .el-main{
	   padding-bottom: 30px;
	    -webkit-transition: left .3s ease-in-out;
	   transition: left .3s ease-in-out;
	   background: #f0f0f0;
	   padding: 0;
   }
   .tags {
       position: relative;
       height: 30px;
	   top: 4px;
	   overflow: hidden;
       background: #fff;
       padding-right: 96px;
       -webkit-box-shadow: 0 5px 10px #ddd;
       box-shadow: 0 5px 10px #ddd;
   }
   .content {
       width: auto;
       height: 660px;
       padding: 10px;
      overflow-y: scroll;
       -webkit-box-sizing: border-box;
       box-sizing: border-box;
   }
  
   .tags ul {
       -webkit-box-sizing: border-box;
       box-sizing: border-box;
       width: 100%;
       height: 100%;
   }
   .tags-close-box {
       position: absolute;
       right: 0;
       top: 0;
       -webkit-box-sizing: border-box;
       box-sizing: border-box;
       padding-top: 1px;
       text-align: center;
       width: 110px;
       height: 30px;
       background: #fff;
       -webkit-box-shadow: -3px 0 15px 3px rgb(0 0 0 / 10%);
       box-shadow: -3px 0 15px 3px rgb(0 0 0 / 10%);
       z-index: 10;
   }
    .el-dropdown {
       vertical-align: top;
     }
     .el-dropdown + .el-dropdown {
       margin-left: 15px;
     }
     .el-icon-arrow-down {
       font-size: 12px;
     }
	 .tags-li {
	     float: left;
	     margin: 3px 5px 2px 3px;
	     border-radius: 3px;
	     font-size: 12px;
	     overflow: hidden;
	     cursor: pointer;
	     height: 23px;
	     line-height: 23px;
	     vertical-align: middle;
	     color: #666;
	     -webkit-transition: all .3s ease-in;
	     transition: all .3s ease-in;
	 }
</style>
