<template>
	<div id="app">
		这是首页，啥也没有啊
	</div>
</template>
<script type="text/javascript">
export default{
		
}
</script>
<style type="text/css" scoped="scoped">
	
</style>