<template>
	<div>
		<el-card>
			<div style="display:flex;">
				<span class="articleTitle"><span style="color: red;">*</span>文章标题:</span>
				<el-input placeholder="请输入内容" v-model="articleData.title" clearable maxlength='15' show-word-limit>
				</el-input>
			</div>
			<div style="display:flex;margin-top: 20px;">
				<span class="articleTitle"><span style="color: red;">*</span>文章描述:</span>
				<el-input type="textarea" placeholder="请输入内容" v-model="articleData.titleDesciption" clearable maxlength='50'
					show-word-limit>
				</el-input>
			</div>
			<div style="margin-top: 20px;font-size: 13px;font-weight: 600;color: #696868;">
				<span style="color: red;">*</span>是否原创:
				<el-radio v-model="articleData.radio1" label="1" border size='mini'>是原创</el-radio>
				<el-radio v-model="articleData.radio1" label="2" border size='mini'>非原创</el-radio>
			</div>
			<div style="margin-top: 20px;font-size: 13px;font-weight: 600;color: #696868;">
				<span style="color: red;">*</span>是否实战:
				<el-radio v-model="articleData.radio2" label="1" border size='mini'>是实战</el-radio>
				<el-radio v-model="articleData.radio2" label="2" border size='mini'>非实战</el-radio>
			</div>
			<div style="margin-top: 20px;font-size: 13px;font-weight: 600;color: #696868;">
				<span><span style="color: red;">*</span>文章图片：</span><span
					style="color: red;margin-left: 14px;">请点击下方按钮进行上传图片操作</span>
				<div style="padding-left:80px;padding-top: 10px;">
					<el-upload class="upload-demo"
					    action
						multiple
						ref='upload'
						list-type="picture"
						name='image'
						:auto-upload="false"
						:on-remove="handleRemove" 
						:before-remove="beforeRemove"
						:limit="1" 
						:http-request='handleUploadForm'
						:on-exceed="handleExceed"
						:on-change='handleChange'
						:file-list="fileList">
						<el-button size="small" type="primary">点击上传</el-button>
						<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
					</el-upload>
				</div>
			</div>
			<div style="margin-top: 20px;font-size: 13px;font-weight: 600;color: #696868;">
				<span style="color: red;">*</span>文章标签:
				<el-checkbox-group v-model='checkList' style='display: inline;margin-left: 10px;' @change='handleCheckBook'>
					  <el-checkbox label="Vue"></el-checkbox>
					  <el-checkbox label="HTML"></el-checkbox>
					  <el-checkbox label="Css"></el-checkbox>
					  <el-checkbox label="JavaScript"></el-checkbox>
					  <el-checkbox label="项目实战"></el-checkbox>
					  <el-checkbox label="算法"></el-checkbox>
					  <el-checkbox label="TCP"></el-checkbox>
				</el-checkbox-group>
				<div style="margin-left: 75px;margin-top: 5px;">
					<el-button type="primary" style='height:30px;line-height: 8px;' @click='addTag'>标签库不够?点击我添加新的标签
					</el-button>
				</div>
			</div>
			<el-divider></el-divider>
			<div style="margin-top: 20px;font-size: 13px;font-weight: 600;color: #696868;">
				<span style="color: red;">*</span>文本标签: <span class="myTags">标签一</span>
				<mavon-editor v-model="articleData.value" style='margin-top: 20px;min-height: 500px;'@save='saveArticle'/>
				<el-button type="primary" style='width: 100%;height: 60px;margin-top: 20px;' @click='releaseArticle'>发表文章</el-button>
			</div>
		</el-card>
		<el-dialog title="添加标签" :visible.sync="dialogVisible" width="25%">
			   标签名：<el-input v-model='articleData.tagName' maxlength='7' clearable show-word-limit placeholder='请输入标签名'></el-input>
			   标签颜色：<el-input v-model='articleData.tagColor' maxlength='16' clearable show-word-limit placeholder='请输入十六进制颜色'></el-input>
	        <span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import { adminUploadImage } from '../../../server/api/index.js'
	import { getUserInfo } from '../../../server/api/store.js'
	export default {
		data() {
			return {
				articleData:{
					title:'',
					titleDesciption: '',
					radio1: '1',
					radio2: '1',
					tagName:'',
					tagColor:'', 
					value:'',
					article:'',
					checkList:[]
				},
				dialogVisible: false,
				fileList: [],
				checkList: ['Vue','HTML','Css','JavaScript','项目实战','算法','TCP']
			}
		},
		methods: {
			handleRemove(file, fileList) {
				this.$message.warning('您已移出需要上传的图片');
			},
			handleExceed(files, fileList) {
				this.$message.warning(`当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
			},
			beforeRemove(file, fileList) {
				return this.$confirm(`确定移除 ${ file.name }？`);
			},
			addTag() {
				this.dialogVisible  = true;
			},
			saveArticle(val,render){
				this.articleData.article = render;
				return this.$message.success('保存成功');
			},
			handleChange(file,fileList){
				this.fileList = fileList;
			},
			handleCheckBook(val){
				this.articleData.checkList = val; 
			},
			handleUploadForm(param){                         //此函数会被 this.$refs.upload.submit() 自动触发
				let fd = new FormData()// FormData 对象
				let allObj = {
					title:this.articleData.title,
					titleDescription:this.articleData.titleDesciption,
				    radio1:this.articleData.radio1,
					radio2:this.articleData.radio2,
					article:this.articleData.article,
					checkList:this.articleData.checkList,
					markdown:this.articleData.value,
					account:this.account
					};
                    fd.image = param;
					fd.append('image',param.file);
					fd.append('data',JSON.stringify(allObj));
				adminUploadImage(fd).then(data=>{
					if(data.code===200){
						this.$message.success(data.msg);
						this.fileList = [ { name:data.name,url:data.url } ];
						this.articleData = {};//清空输入框
					}else{
						this.$message.warning(data.msg);
					}
				}).catch(err=>console.log(err));
			},
			releaseArticle(){
			    this.$nextTick(()=>{
					if(this.articleData.title==='') return this.$message.error('标题不能空')
					if(this.articleData.titleDesciption==='') return this.$message.error('描述不能空')
				    if(this.fileList.length===0) return this.$message.error('请上传图片')
					//此处判断标签
					if(this.articleData.value==='') return this.$message.error('富文本不能为空');
					this.$refs.upload.submit();//最后进行图片与其余data数据的上传
				});
			},
		},
		mounted() {
			
		},
		computed: {
			account() {
				return getUserInfo().account;
			}
		}
	}
</script>

<style scoped="scoped">
	.articleTitle {
		display: inline-block;
		width: 85px;
		line-height: 37px;
		font-size: 13px;
		font-weight: 600;
		color: #696868;
	}

	.el-radio {
		margin-left: 20px;
	}

	.myTags {
		display: inline-block;
		border: 1px solid red;
		border-radius: 5px;
		color: burlywood;
		padding: 2px 2px 2px 2px;
		margin-right: 10px;
	}
	.mavonEditor {
	  width: 100%;
	  height: 100%;
	}
</style>
