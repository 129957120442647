<template>
	<div>
		<el-table :data="tableData" style="width: 100%" v-loading='loading'>
			<el-table-column prop='id' label="id" width='100'>
			</el-table-column>
			<el-table-column label="QQ头像">
				<template slot-scope="scope">
					<el-image style="width: 60px; height: 60px" :src="scope.row.avatar"
						:preview-src-list="[scope.row.avatar]">
					</el-image>
				</template>
			</el-table-column>
			<el-table-column prop="name" label="昵称">
			</el-table-column>
			<el-table-column prop="email" label="邮箱">
			</el-table-column>
			<el-table-column prop="power" label="评论权限">
				<template slot-scope='scope'>
					<el-switch 
					@change='getRowData(scope.row)' 
					v-model='scope.row.remarkpower'
					active-color="#13ce66"
					inactive-color="#ff4949"
					>
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column prop="remarkSum" label="每日评论数量">
				<template slot-scope='scope'>
					<el-input @blur='editRemarkNum(scope.row)' v-model="scope.row.remarknum">
					</el-input>
				</template>
			</el-table-column>
			<el-table-column label="身份">
				<template slot-scope="scope">
					<el-tag v-if='scope.row.status==="管理员" ' size='mini'>{{scope.row.status}}</el-tag>
					<el-tag type="success" v-else size='mini'>{{scope.row.status}}</el-tag>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button size="mini" disabled>编辑</el-button>
					<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handleCurrentChange" :page-size="10" layout="prev, pager, next, jumper,total"
			:total="total">
		</el-pagination>
	</div>
</template>

<script>
	import {
		adminUser,
		adminRemarkPower,
		adminRemarkNum,
		adminUserDelete
	} from '../../../server/api/index.js'; //普通登录入口
	import {
		getUserInfo
	} from '../../../server/api/store.js'; //获取用户登录信息
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				total: null,
				cuurentPage: 1
			}
		},
		methods: {
			handleDelete(index, row) {
				this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					let {
						email,
						id
					} = row;
					let account = getUserInfo().account;
					let ref = await adminUserDelete({
						email,
						id,
						account
					});
					this.loading = true;
					if (ref.success) {
						this.$message.success(ref.msg);
						this.initData(); //刷新当前页面
						this.loading = false;
					} else {
						this.$message.error(ref.msg);
						this.loading = false;
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			async handleCurrentChange(val) { //数据翻页
				this.cuurentPage = val;
				this.loading = true;
				let ref = await adminUser({
					page: val
				});
				if (ref.success) {
					this.tableData = ref.data;
					this.initData(this.cuurentPage);//刷新当前页面
					this.loading = false;
				}
			},
			async initData() { //数据初始化
				let ref = await adminUser({
					page: this.cuurentPage
				});
				if (ref.success) {
					ref.data.forEach(val => {
						val.remarkpower = Boolean(val.remarkpower)
					}); //进行布尔值转化
					this.tableData = ref.data;
					this.loading = false;
					this.total = ref.length;
				}
			},
			async getRowData(data) { //评论权限的修改
				let account = getUserInfo().account;
				let email = data.email;
				let val = data.remarkpower;
				this.loading = true;
				let ref = await adminRemarkPower({
					account,
					val,
					email
				});
				if (ref.success) {
					this.$message.success(ref.msg);
					this.loading = false;
				} else {
					this.initData(); //刷新当前页面
					this.$message.error(ref.msg);
					this.loading = false;
				}
			},
			async editRemarkNum(data) { //评论条数的修改
				let reg = /^[0-9]*$/;
				let isNumber = reg.test(data.remarknum);
				let account = getUserInfo().account;
				if (!isNumber) return this.$message.error('请输入纯数字');
				this.loading = true;
				let ref = await adminRemarkNum({
					num: data.remarknum,
					email: data.email,
					account
				});
				if (ref.success) {
					this.$message.success(ref.msg);
					this.initData(this.cuurentPage); //刷新页面
					this.loading = false;
				} else {
					this.initData(); //刷新当前页面
					this.$message.error(ref.msg);
					this.loading = false;
				}
			},

		},
		mounted() {
			this.initData(); //页面初始化
		},
	}
</script>

<style scoped="scoped">
	/deep/ .el-table th,
	.el-table tr {
		color: #909399;
		font-weight: 700;
		font-size: 12px;
		background-color: #f5f7fa !important;
	}

	/deep/ .el-input__inner {
		height: 28px;
	}

	/deep/.el-table th>.cell {
		text-align: center;
	}

	/deep/ .el-table--enable-row-transition .el-table__body td {
		text-align: center;
		font-weight: 700;
		font-size: 12px;
	}

	/deep/ .el-pagination__total {
		float: right;
	}
	
</style>
