<template>
	<div id="app">
		<div class="login">
			<div class="ms-login">
				<div class="ms-title">Passer 后台管理系统</div>
				<div class="Input_wrap">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
						<el-form-item prop="account">
							<el-input v-model="ruleForm.account" placeholder="管理员账号" size='small'
								prefix-icon='el-icon-user-solid' maxlength='10' clearable></el-input>
						</el-form-item>
						<el-form-item prop="pwd">
							<el-input v-model="ruleForm.pwd" placeholder="密码" size='small' prefix-icon='el-icon-unlock'
								maxlength='10' clearable show-password></el-input>
						</el-form-item>
					</el-form>
					<el-button type='primary' @click='Login("ruleForm")'>登录</el-button>
					<span class="tip">Tip:访客账号: <span style="color: mediumvioletred;">passer</span> 密码: <span
							style="color:#ffbb72;">[1-9]</span> <span
							style="color: #13ebf5;margin-left: 100px;">游客无权限</span></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		adminLogin
	} from '../../server/api/index.js'; //普通登录入口
    export default {
		name: 'Login',
		data() {
			var checkAccount = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('账号不能为空'));
				}
				if (value.length < 3) {
					return callback(new Error('长度在 3 到 10 个字符'));
				}
				callback();
			};
			var checkPassword = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('密码不能为空'));
				}
				if (value.length < 3) {
					return callback(new Error('长度在 3 到 10 个字符'));
				}
				callback();
			};
			return {
				ruleForm: {
					account: '', //账号
					pwd: '' //密码
				},
				rules: {
					account: [{
						validator: checkAccount,
						trigger: 'blur'
					}],
					pwd: [{
						validator: checkPassword,
						trigger: 'blur'
					}]
				}
			}
		},
		methods: {
			Login(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						let adminInfo = {
							account: this.$refs[formName].model.account,
							pwd: this.$refs[formName].model.pwd
						};
						let ref = await adminLogin(adminInfo);
						if (ref.code === 200) {
							this.$message.success(ref.msg);
							ref.data[0].token = ref.token;
							let adminInfo = ref.data[0];
							window.localStorage.setItem('adminInfo', JSON.stringify(adminInfo));
							this.$refs[formName].resetFields(); //表单重置
							return this.$router.push({
								path: '/admin/home'
							}); //进入首页
						} else {
							this.$message.error(ref.msg);
							this.$refs[formName].resetFields(); //表单重置
						}
					}
					return false;
				});
			},
		}
	}
</script>

<style scoped="scoped">
	#app {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -10;
		zoom: 1;
		background-color: #fff;
		background-repeat: no-repeat;
		background-size: cover;
		-webkit-background-size: cover;
		-o-background-size: cover;
		background-position: center 0;
		background-image: url("../../assets/image/adminLogin.jpg");
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login {
		padding: 0 10px;
		width: 350px;
		overflow: hidden;
		box-shadow: 0 0 4px #fff;
		background: rgba(59, 55, 55, .3);
	}

	.ms-login {
		width: 350px;
		border-radius: 5px;
	}

	.ms-title {
		width: 100%;
		line-height: 50px;
		text-align: center;
		font-size: 20px;
		color: #fff;
		border-bottom: 1px solid #ddd;
	}

	.Input_wrap {
		line-height: 40px;
	}

	.Input_wrap .el-button {
		width: 100%;
		margin-top: 20px;
	}

	.tip {
		color: #fff;
		font-size: 12px;
	}

	.el-form-item {
		margin-bottom: unset;
		margin-top: 19px;
	}


	/*以下为后台管理系统样式*/
	.loginAccount {
		margin-top: 25px;
	}

	.loginAccount .qqLogin {
		width: inherit;
		height: inherit;
		text-align: center;
		padding: 0px 150px 0 150px;
		margin-bottom: 15px;
		margin-top: -10px;
	}

	.Input_wrap .el-button[data-v-2ba5fe30] {
		margin-top: 20px;
	}
	.qqbtn{
		display: inline-block;
		width: 60px;
		text-align: center;
	}
</style>
